<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <page-header
          :title="'Company Info'"
          :sub-heading="true"
          :sub-header="'Dashboard / Company Info'"
          :link-name="'View Company'"
          :link-url="'/view-company'"
        >
          <ca-button :type="'ghost'" class="mr-1" @click.native="goToEdit"
            >Edit Company</ca-button
          >
          <ca-button :color="'danger'" class="mr-1" @click.native="confirmDelete"
            >Deactivate Company</ca-button
          >
        </page-header>

        <full-page-content-container>
          <company-info-details-header
            v-if="companyInfo !== null"
            :company-info="companyInfo"
          ></company-info-details-header>

          <ca-popup
            :header="'Deactivate Company'"
            :toggle="openDeletePopup"
            @open="confirmDelete"
            @close="cancelDelete"
          >
            <template v-slot:content>
              Are you sure you want to deactivate this Company?
            </template>
            <template v-slot:actions>
              <ca-button @click.native="deactivateCompany">Yes</ca-button>
            </template>
          </ca-popup>

          <ca-popup
            :header="'Delete Financial Modeling'"
            :toggle="openDeleteFMPopup"
            @open="confirmDeleteFM"
            @close="cancelDeleteFM"
          >
            <template v-slot:content>
              Are you sure you want to delete this Financial Modeling?
            </template>
            <template v-slot:actions>
              <ca-button @click.native="deleteModal">Yes</ca-button>
            </template>
          </ca-popup>

          <grid-table
            v-if="companyInfo && companyCalculation"
            :column-defs="columnDefs"
            :row-data="rowData"
          >
            <template v-slot:cell-renderer="slotProps">
              <div>
                <span class="icon-button mr-1"
                  
                  >
                  <img
                    :src="require('@/assets/images/icons/download.svg')"
                    alt="edit icon"
                /></span>
                <span
                  class="icon-button mr-1"
                  @click="
                    viewCompanyModels(slotProps.currentRowData.id)
                  "
                  ><img
                    :src="require('@/assets/images/icons/view.svg')"
                    alt="view icon"
                /></span>
                <span class="icon-button" @click="confirmDeleteFM(slotProps.currentRowData)"
                  ><img /><img
                    :src="require('@/assets/images/icons/delete.svg')"
                    alt="delete icon"
                /></span>
              </div>
            </template>
          </grid-table>
          <div class="no-calculation" v-else>
            <p>No calculation found for this company</p>
          </div>
        </full-page-content-container>
      </div>
    </div>

    <vue-html2pdf  
      :filename="'Model-' + $route.params.id + '.pdf'"
      :show-layout="true"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a3"
      pdf-orientation="landscape"
      id="PdfPrint"
      ref="pdf"
      v-if="current != null"
          
         >
         <section slot="pdf-content" style="width:1300px">
          <section slot="pdf-item" style="padding:40px 26px;color:black;position:relative">
            <img :src="avatar" height="200" style="max-width:70%;margin: 300px auto 0;display:block" />
              <h2 style="width:100%;color:black;text-align:center;font-size:1.5rem;font-weight:bold;margin-top:20px;letter-spacing:1px"
                >Financial Projection For {{company?company.name:'' }}</h2
              >
          <h5 style="width:100%;text-align:center;font-weight:bold;margin-top:15px">{{this.months[new Date().getMonth()]+" "+new Date().getFullYear()}}</h5>
          <img src="@/assets/images/logo/logo.svg" width="170" style="margin-top:320px;padding-left:25px;" />
          <h5 style="margin:15px 0 0 0;padding:0 0 0 25px;font-weight:bold;color:#444444;font-size:1.1rem;letter-spacing:0.5px"
            >This model was generated on <a href="www.caena.io" style="color:#1D817F">Caena.io</a></h5
          >
        </section>
        <section slot="pdf-item" style="padding:0px 15px;color:black;position:relative; height:1000px; width:1300px; margin-top:200px;" >
        
          <p class="subject">Financial P&L Summary</p>
          <div class="table-conatiner watermark">
      
            <div class="table-row row-index">
              <div class="table-cell table-head">
                <span>
                  StartUp Financial projection
                </span>
              
              </div>
              <div class="table-cell cell-index" v-for="(rowIndex, index) in rowIndexs" :key="index" :class="index % 2 == 0? '': 'gray'" >
              {{rowIndex.name}}
              </div>
            </div>
            <div class="table-row row-value">
              <div class="table-wrap table-head" >
                <div class="cell" >
                Historical Period
                </div>
                <div class="years">
                  <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'" :class="index % 2 == 0? '': 'gray'">
                    {{current.year}}
                  </div>
                
                  <div style="width:50%"  class="year" v-for="(historic, index) in historics" :key="index" :class="index % 2 == 0? '': 'gray'">
                    {{historic.year}}
                  </div>
                
                </div>
              </div>
                <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs" :key="index" :class="index % 2 == 0? '': 'gray'">
                  <div class="years">
                  <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{scaled(current[rowIndex.field])}}
                  </div>
                  <div style="width:50%" class="year-value" v-for="(historic, index) in historics" :key="index">
                    {{scaled(historic[rowIndex.field])}}
                  </div>
                </div>
              </div>
            
            </div>
            <div class="table-row row-value">
              <div class="table-wrap table-head">
                <div class="cell">
                Projection Period
                </div>
                <div class="years">
                  <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{current.year}}
                  </div>
                  <div style="width:50%" class="year" v-for="(projection, index) in projections" :key="index" >
                    {{projection.year}}
                  </div>
                </div>
              </div>
              <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs" :key="index" :class="index % 2 == 0? '': 'gray'">
                <div class="years">
                  <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{scaled(current[rowIndex.field])}}
                  </div>
                  <div style="width:50%" class="year-value" v-for="(projection, index) in projections" :key="index">
                    {{scaled(projection[rowIndex.field])}}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section slot="pdf-item" style="padding:0px 15px;color:black;position:relative; width:1300px; margin:auto;">
          <p class="subject">Financial Metrics Summary</p>
          <div class="table-conatiner watermark">
      
            <div class="table-row row-index">
              <div class="table-cell table-head">
                <span>
                  StartUp Financial projection
                </span>
              
              </div>
              <div class="table-cell cell-index" :class="index % 2 == 0? '': 'gray'" v-for="(rowIndex, index) in rowIndexs2" :key="index">
              {{rowIndex.name}}
              </div>
            </div>
            <div class="table-row row-value">
              <div class="table-wrap table-head" >
                <div class="cell" >
                Historical Period
                </div>
                <div class="years">
                  <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'" :class="index % 2 == 0? '': 'gray'">
                    {{current.year}}
                  </div>
                
                  <div style="width:50%"  class="year" v-for="(historic, index) in historics" :key="index" :class="index % 2 == 0? '': 'gray'">
                    {{historic.year}}
                  </div>
                
                </div>
              </div>
                <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs2" :key="index" :class="index % 2 == 0? '': 'gray'">
                  <div class="years">
                  <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{scaled(current[rowIndex.field])}}
                  </div>
                  <div style="width:50%" class="year-value" v-for="(historic, index) in historics" :key="index">
                    {{scaled(historic[rowIndex.field])}}
                  </div>
                </div>
              </div>
            
            </div>
            <div class="table-row row-value">
              <div class="table-wrap table-head">
                <div class="cell">
                Projection Period
                </div>
                <div class="years">
                  <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{current.year}}
                  </div>
                  <div style="width:50%" class="year" v-for="(projection, index) in projections" :key="index" >
                    {{projection.year}}
                  </div>
                </div>
              </div>
              <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs2" :key="index" :class="index % 2 == 0? '': 'gray'">
                <div class="years">
                  <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{scaled(current[rowIndex.field])}}
                  </div>
                  <div style="width:50%" class="year-value" v-for="(projection, index) in projections" :key="index">
                    {{scaled(projection[rowIndex.field])}}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section slot="pdf-item" style="padding:40px 26px;color:black;position:relative; height:auto; width:1300px; margin:auto">
            <p class="chart-subject">Projection Year chart</p>
            <vue-apex-charts
              :options="options"
              :series="series"
              height="350"
              class="watermark"
            ></vue-apex-charts>
        </section>
      </section>
   </vue-html2pdf>
  </div>
</template>

<script>
import Symbols    from "./settings/Symbols.js";
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import CaButton from "../../components/buttons/CaButton";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import CompanyInfoDetailsHeader from "./components/CompanyInfoDetailsHeader";
// import company_info from "../users/db/company_info";
import GridTable from "../../components/tables/GridTable";
import axios from "axios";
import CaPopup from "../../components/popup/CaPopup";
import VueHtml2pdf from 'vue-html2pdf';
export default {
  name: "ViewCompanyInfo",
  components: {
    GridTable,
    CompanyInfoDetailsHeader,
    PageHeader,
    CaButton,
    CaPopup,
    FullPageContentContainer,
    VueHtml2pdf
  },
  data() {
    return {
      scales:
        [
          { icon: 'None', text:'None',  value:1 },
          { icon: '1 k',  text:'Thousands', value:1000 },
          { icon: '1 M',  text:'Milions',   value:1000000 },
          { icon: '1 B',  text:'Bilions',   value:1000000000 }
        ],
      series:
        [
          { name: "EBITDA",     type:'bar',  data:[] },
          { name: "Revenue",     type:'bar',  data:[] },
          { name: "Net Profit",     type:'line',  data:[] },
        ],
        options:
        {
          fill: { opacity: 1 },
          plotOptions: { bar: { rangeBarGroupRows: false, columnWidth: '50%', distributed: false, } },
          colors: ["#3DD598", "#FFBC2C", "#1d817f"],
          chart: { height: 350, zoom: { enabled: false }, toolbar: { show: (this.setup && this.allow) ? true : false } },
          dataLabels: { enabled: false },
          stroke: { colors: ['#fff','#fff','#1d817f'], curve: ["stepline","stepline","straight"], width: [3,3,4] },
          markers: { colors: ['#CCCCCC'], strokeColors: '#1d817f', strokeWidth: 3, size: [3], hover: { sizeOffset: 2 } },
          legend: { itemMargin: { horizontal: 10, vertical: 15 } },
          grid: { padding: { top: 20, bottom: 0 }, row: { opacity: 0.75 } },
          xaxis: { categories: [], tickPlacement: 'between', position: "bottom", title: false, labels: { offsetY: 2 } },
          yaxis:
          [
            { seriesName: 'A', tickAmount: 5, axisTicks: { show: true }, axisBorder: { show: true, }, labels:{} },
            { seriesName: 'A', tickAmount: 5, show: false, labels:{} },
            { seriesName: 'B', tickAmount: 5, opposite: true, labels:{} }
          ],
          responsive:
          [
            { breakpoint: 768,  options: { legend: { position: 'top', horizontalAlign: 'left', itemMargin: { vertical: 10 } }, grid: { padding: { top:20, bottom: 20 } } } },
            { breakpoint: 4096, options: { legend: { position: 'top', horizontalAlign: 'right', itemMargin: { vertical: 0 } }, grid: { padding: { top:20, bottom: 0 } } } }
          ]
        },

      rowIndexs:[
        {
          name: "Revenue",
          field: "sales"
        },
        {
          name: "COGS",
          field: "cost_of_goods_sold"
        },
        {
          name: "Gross Profit",
          field: "grossProfit"
        },
        {
          name: "SG&A",
          field: "selling_general_administrative"
        },
        {
          name: "EBITDA",
          field: "ebitda"
        },
        {
          name: "D&A",
          field: "depreciation_amortization"
        },
        {
          name: "EDBIT",
          field: "interest_value"
        },
        {
          name: "PBT",
          field: "ebit"
        },
        {
          name: "Taxes",
          field: "taxes"
        },
           {
          name: "Net Profit (Loss)",
          field: "ebiat"
        },
      ],
      rowIndexs2:[
        {
          name: "Revenue Growth",
          field: "sales"
        },
        {
          name: "Gross Profit Margin",
          field: "grossProfitMargin"
        },
        {
          name: "EBITDA Margin",
          field: "ebitdaMargin"
        },
        {
          name: "Net Profit Margin",
          field: "ebitMargin"
        },
        {
          name: "Taxes",
          field: "taxes"
        },
      ],

      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Financial Model",
          field: "financial_model",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Created",
          field: "created",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Period",
          field: "period",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Updated",
          field: "updated",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          hasCellRendererFramework: true,
        },
      ],
      rowData: [],
      companyInfo: null,
      companyCalculation: null,
      openDeletePopup: false,
      openDeleteFMPopup: false,
      currentModel: null,

      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      month_short: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
      avatar: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=',
      company:[],
      historics:[],
      current:null,
      projections:null,
      model_currency:'',
    };
  },
  methods: {
    goToEdit() {
      const companyId = this.$route.params.id;
      this.$router.push({
        name: "edit-company-info",
        params: { id: companyId },
      });
    },
    viewCompanyModels(modelID) {
      this.$router.push({
        name: "view-company-models",
        params: { id: modelID },
      });
    },
    getCompanyInfo() {
      const loading = this.$vs.loading();
      axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
      axios
        .get(
          `${this.$store.state.server.requestUri}/admin/show-company/${this.$route.params.id}`
        )
        .then((res) => {
          this.companyInfo = res.data.data.company;
          console.log(this.companyInfo);
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.log(err);
        });
    },
    showAllCompanyCalculation() {
      if (this.companyInfo !== null) {
        axios.defaults.headers.common["Authorization"] =
          this.$store.state.accessToken;
        axios
          .get(
            `${this.$store.state.server.requestUri}/admin/show-all-company-calculation/${this.$route.params.id}`
          )
          .then((res) => {
            const data = res.data.data;
            if (data.calculation.length > 0) {
              this.companyCalculation = true;
              // const dataid = data.calculation[0][0].calculated.id;
              // console.log(data.calculation);
              for (let index = 0; index < data.calculation[0].length; index++) {
                const element = {
                  id: data.calculation[0][index].calculated.id,
                  financial_model: this.getCalculationName(data.calculation[0][index], data.calculation[0][index].calculated.id),
                  created: this.formatDate(data.calculation[0][index].calculated.created_at),
                  period:
                    data.calculation[0][index].calculated.nwc_calculation[0][0]
                      .period,
                  updated: this.formatDate(
                    data.calculation[0][index].calculated.updated_at
                  ),
                };

                console.log(element);
                this.rowData.push(element);
              }
              console.log(this.rowData);
            }
          })
          .catch((err) => console.log(err));
      }
    },
    getCalculationName (model=null, id)
    {
      let name =''
      try
      {
        name = 'ID '+model.calculated.id;

        if (model.input_current.title)
        {
          let title = JSON.parse(model.input_current.title);
          if (title)
          {
            name = title[id]?title[id]:name;
          }
        }
      }
      catch($)
      {
        name = 'Model'
      }

      if(name=='ID 0') name = 'ID '+model.calculated.id;

      return name;

    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [day, month, year].join("-");
    },
    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete() {
      this.openDeletePopup = true;
    },
    deactivateCompany() {
      const loading = this.$vs.loading();
      axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/deactivate-profile/${this.$route.params.id}`
        )
        .then(() => {
          loading.close();
          this.$toast.success("Company deactivated successfully");
          // this.$router.push({ name: "companies" });
        })
        .catch((err) => {
          loading.close();
          this.$toast.error(err.response.data.message);
        });
    },
    cancelDeleteFM() {
      this.openDeleteFMPopup = false;
    },
    confirmDeleteFM(popUpData = null) {
      if (popUpData !== null) {
        this.currentModel = popUpData;
        // console.log(this.currentModel);
      }
      this.openDeleteFMPopup = true;
    },
    deleteModal(){
      const loading = this.$vs.loading();
      axios
        .delete(
          `${this.$store.state.server.requestUri}/admin/delete-single-calculation/${this.currentModel.financial_model}`
        )
        .then((res) => {
          loading.close();
          this.$toast(
            `calculation with id ${this.currentModel.financial_model} deleted successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.$toast(`An error occurred while deleting calculation`, {
            position: "top-center",
            type: "error",
          });
          console.log(err.response);
        });
    },


    async getFinancialDaTa(id){
      const loading = this.$vs.loading();
      axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
      axios.get(`${this.$store.state.server.requestUri}/admin/show-single-calculation/${id}`)
        .then((res) => {
          this.company = res.data.data.company;
          this.historics = res.data.data.calculation[0].dfc_calculation.historic;
          this.current = res.data.data.calculation[0].dfc_calculation.current;
          this.projections = res.data.data.calculation[0].dfc_calculation.projection[0];
          this.model_currency = res.data.data.inputs.model_currency
            this.options.xaxis.categories.push(this.current.type);
            this.series[0].data.push(this.current.ebitda);
            this.series[1].data.push(this.current.sales);
            this.series[2].data.push(this.current.ebiat);
  
          for (const i in this.projections) {
            this.options.xaxis.categories.push(this.projections[i].type);
            this.series[0].data.push(this.projections[i].ebitda);
            this.series[1].data.push(this.projections[i].sales);
            this.series[2].data.push(this.projections[i].ebiat);
            // console.log(this.projections);
        
          }
          loading.close();

            // this.options.yaxis[0].labels.formatter = value => { return ( this.formatted(value,true) )};
            // this.options.yaxis[1].labels.formatter = value => { return ( this.formatted(value,true) )};
            // this.options.yaxis[2].labels.formatter = value => { return ( this.formatted(value,true) )}
          
        })
        .catch((err) => console.log(err));
    },

    scaled(number)
    {
      if ( isNaN(number) ) return 0;

      number = number /1;
      number = number.toLocaleString(undefined, {minimumFractionDigits: 0,maximumFractionDigits: 1});

      return number
    },
    formatted(number,currency)
    {
      if ( isNaN(number) ) return 0;

      number = number.toLocaleString(undefined, {minimumFractionDigits: 0,maximumFractionDigits: 1});

      if ( currency )
      {
        number = Symbols[this.model_currency] + number;
      }
      return number
    },
    scaledLabel(number)
    {
      if ( isNaN(number) ) return 0;

      number = Symbols[this.model_currency] + Math.round(number / this.range);
      number = number.toLocaleString(undefined, {minimumFractionDigits: 0,maximumFractionDigits: 1});
      number = number+this.scaleLabels[this.range];

      return number
    },
    async getScale()
    {
      this.scaleLabels             = [];
      this.scaleLabels[1]          = '';
      this.scaleLabels[1000]       = 'k';
      this.scaleLabels[1000000]    = 'M';
      this.scaleLabels[1000000000] = 'B';
    },
    downloadPdf(id) {
      this.getCompanyInfo(id);
      if (this.current != null ) {
        this.$refs.pdf.generatePdf();
      } 
    },
  
  },
  watch: {
    companyInfo() {
      this.showAllCompanyCalculation();
    },
  },
  created() {
    this.getCompanyInfo();
    this.showAllCompanyCalculation();
  },
};
</script>

<style scoped>
.no-calculation {
  text-align: center;
  width: 100%;
}
</style>
